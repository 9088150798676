export class EventCallback {
  public eventCallbacks: Function[] = [];

  constructor() {
    // Empty
  }

  public onEvent(callbackFunction: Function) {
    this.eventCallbacks.push(callbackFunction);
  }

  public offEvent(callbackFunction: Function) {
    const index = this.eventCallbacks.indexOf(callbackFunction);
    if (index !== -1) {
      this.eventCallbacks.splice(index, 1);
    }
  }
}
