import { ApiHandler } from './api-handler';
import { CwElementHandler } from './cw-element-handler';
import { Settings } from './types/settings.type';
import { Target } from './types/target.type';

const target: Target = {
  settings: 'OnlimCwSettings',
  settingsFallback: 'OnlimChatbotV2',
  api: 'OnlimCw',
  apiFallback: 'OnlimV2',
};

const w: any = window;

if (!w[target.settings]) {
  w[target.settings] = w[target.settingsFallback];
}

const settings: Settings = w[target.settings];
settings.widgetName = 'chatwidget-v2';

const htmlContent =
  require('../../../dist/chat-app/app-v2/index.html') as string;

const cssContent = require('../../../dist/chat-app/app-v2/host.css');

const cwElementHandler = new CwElementHandler(
  window,
  htmlContent,
  cssContent,
  target,
  settings,
);

const apiHandler = new ApiHandler(
  cwElementHandler,
  w[target.api] || w[target.apiFallback],
);

if (w[target.api]) {
  w[target.api] = apiHandler.api;
} else {
  w[target.api] = w[target.apiFallback] = apiHandler.api;
}

// auto booter
if (settings.autoBoot !== false && !apiHandler.bootIsQueued) {
  cwElementHandler.boot();
}
