import { CwElementHandler } from './cw-element-handler';
import { EventCallback } from './event-callback';
import { Api, InitialApi } from './types/api.type';
import { WebsiteAction } from './website-action';

export class ApiHandler {
  public api: Api;

  public bootIsQueued: boolean;

  private cwElementHandler: CwElementHandler;

  private queue: any[];

  private eventCallback = new EventCallback();

  private websiteAction = new WebsiteAction();

  constructor(
    private _cwElementHandler: CwElementHandler,
    private initialApi: InitialApi,
  ) {
    this.cwElementHandler = this._cwElementHandler;
    this.api = this.createApi();
    this.queue = this.getQueue(this.initialApi);
    this.bootIsQueued = this.isBootQueued();
    this.processQueue();
  }

  private internalApi = {
    boot: (userInformation?: any, widgetId?: string) => {
      this.cwElementHandler.boot(userInformation, widgetId);
    },
    shutdown: () => this.cwElementHandler.shutdown(),
    open: () => this.open(),
    close: () => this.close(),
    _ready: () => this._ready(),

    sendMessage: (msg: string | { text: string; payload: string }) => {
      this.sendMessage(msg);
    },

    onEvent: (callbackFunction: Function) =>
      this.eventCallback.onEvent(callbackFunction),
    offEvent: (callbackFunction: Function) =>
      this.eventCallback.offEvent(callbackFunction),
    _getEventCallbacks: () => this.eventCallback.eventCallbacks,

    onWebsiteAction: (websiteAction: Function) =>
      this.websiteAction.onWebsiteAction(websiteAction),
    offWebsiteAction: (websiteAction: Function) =>
      this.websiteAction.offWebsiteAction(websiteAction),
    _getWebsiteActions: () => this.websiteAction.websiteActions,
  };

  private createApi(): Api {
    return (...args) => {
      const argLength = args.length;
      const arg = new Array(argLength);
      for (let i = 0; i < argLength; i += 1) {
        arg[i] = args[i];
      }
      const action: string = arg[0];
      if (action && this.internalApi[action]) {
        return this.internalApi[action].apply(undefined, arg.slice(1));
      }
      return undefined;
    };
  }

  private getQueue(api: InitialApi): any[] {
    return (api && api.q) || [];
  }

  private isBootQueued(): boolean {
    return this.queue.some((action) => {
      return action[0] === 'boot' || action[0] === 'shutdown';
    });
  }

  private processQueue() {
    // Copy queue to ensure actions that can't run yet are not readded
    const initialQueue = [...this.queue];
    this.queue.length = 0;

    while (initialQueue.length) {
      this.api.apply(undefined, initialQueue.shift());
    }
  }

  private _ready() {
    this.cwElementHandler.booted = true;
    while (this.queue.length) {
      const delayedAction = this.queue.shift();
      this.internalApi[delayedAction.name].apply(undefined, [
        delayedAction.args,
      ]);
    }
  }

  private open() {
    if (!this.cwElementHandler.booted) {
      this.queue.push({ name: 'open' });
    } else if (
      this.cwElementHandler.element &&
      this.cwElementHandler.element.contentWindow &&
      (this.cwElementHandler.element.contentWindow as any).OnlimAPI
    ) {
      (this.cwElementHandler.element.contentWindow as any).OnlimAPI.open();
    }
  }

  private close() {
    if (!this.cwElementHandler.booted) {
      this.queue.push({ name: 'close' });
    } else if (
      this.cwElementHandler.element &&
      this.cwElementHandler.element.contentWindow &&
      (this.cwElementHandler.element.contentWindow as any).OnlimAPI
    ) {
      (this.cwElementHandler.element.contentWindow as any).OnlimAPI.close();
    }
  }

  private sendMessage(msg: string | { text: string; payload: string }) {
    if (!this.cwElementHandler.booted) {
      this.queue.push({ name: 'sendMessage', args: msg });
    } else if (
      this.cwElementHandler.element &&
      this.cwElementHandler.element.contentWindow &&
      (this.cwElementHandler.element.contentWindow as any).OnlimAPI
    ) {
      (this.cwElementHandler.element.contentWindow as any).OnlimAPI.sendMessage(
        msg,
      );
    }
  }
}
