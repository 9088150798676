export class WebsiteAction {
  public websiteActions: Function[] = [];

  constructor() {
    // Empty
  }

  public onWebsiteAction(websiteAction: Function) {
    this.websiteActions.push(websiteAction);
  }

  public offWebsiteAction(websiteAction: Function) {
    const index = this.websiteActions.indexOf(websiteAction);
    if (index !== -1) {
      this.websiteActions.splice(index, 1);
    }
  }
}
